// src/reducers/emailReducer.js

const initialState = {}  
  
  
  const PremioFisicoReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_PREMIO":
        console.log("action payload");
        return {
          ...state,
          ...action.payload
        };
      default:
        return state;
    }
  };
  
  
  
  export default PremioFisicoReducer;
  