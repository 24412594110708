import { useWindowSize } from "../../components/hooks.js";
import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./NoPrize.module.css";
import fondoImg from "../../assets/fondo-form.jpg";
import fondoMobile from "../../assets/fondoMobile.jpg";
import image1 from "../../assets/titulo_promo-form.png";
import image1Mobile from "../../assets/logo_promo.png";
import image2 from "../../assets/perrito_premios-form.png";
import image3 from "../../assets/logo_suave.png";
import { legal, terminos } from "../../components/datos";
import "remixicon/fonts/remixicon.css";
import Modal from "../../components/modals/ModalTerminos.jsx";

const NoPrize = () => {
  const size = useWindowSize();
  const isMobile = size.width < 1023;

  useEffect(() => {
    localStorage.getItem("fechaGanador")
   
    console.log("user");
    console.log("storage",localStorage);

  },[])

  // MODAL --------------------------
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(terminos);

  const openModal = () => {
    setShowModal(true);
  };

  const replaceTerminosWithLink = (text) => {
    const parts = text.split("*Aplican términos y condiciones");
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && (
          <span className={styles.link} onClick={openModal}>
            *Aplican términos y condiciones
          </span>
        )}
      </React.Fragment>
    ));
  };

  return (
    <section className={styles.container}>
      {isMobile ? (
        <img src={fondoMobile} alt="img" className={styles.fondoImgMobile} />
      ) : (
        <img src={fondoImg} alt="img" className={styles.fondoImg} />
      )}

      <div className={styles.content}>
        {isMobile && (
          <img src={image3} alt="img" className={styles.logoSuave2} />
        )}
        <div className={styles.imgContainer}>
          <img
            src={isMobile ? image1Mobile : image1}
            alt="img"
            className={styles.image1}
          />
          <img src={image2} alt="img" className={styles.image2} />
        </div>
        <div className={styles.boxContainer}>
          {!isMobile && (
            <img src={image3} alt="img" className={styles.logoSuave} />
          )}
          <div className={styles.infoContent}>
            <div className={styles.titleContent}>
              <h2 className={styles.title}>¡ESTA VEZ</h2>
              <h2 className={styles.title}>NO GANASTE!</h2>
            </div>
            <p className={styles.text}>
              Sigue participando comprando más productos Suave de la promoción.
            </p>
            <span className={styles.textNegrita}>
              ¡Son miles de premios y uno puede ser tuyo!
            </span>
            <Link to="/dashboard">
              <button className={styles.button}>INGRESAR OTRO CÓDIGO</button>
            </Link>
          </div>
        </div>
      </div>
      <span className={styles.textFooter}>
        {replaceTerminosWithLink(legal)}
      </span>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={modalContent.title}
        content={modalContent}
      />
    </section>
  );
};

export default NoPrize;
