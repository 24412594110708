import { combineReducers } from "redux";
import userReducer from "./userReducer";
import emailReducer from "./emailReducer";
import { formSaveReducer } from "./formReducer";
import authReducer from "./authReducer.jsx";
import PremioFisicoReducer from "./premioFisicoReducer.js";


const rootReducer = combineReducers({
  user: userReducer,
  email: emailReducer,
  formSave: formSaveReducer,
  auth: authReducer,
  premioFisico:PremioFisicoReducer
});

export default rootReducer;
