import React from "react";
import styles from "./table.module.css";

const Table = ({ data }) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>Anexo N° 1</h3>
        <h3 className={styles.titleSub}>Puntos de Recojo (Direcciones, Fechas y Horarios)</h3>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Ciudad</th>
            <th>Dirección</th>
            <th>Días de Recojo</th>
            <th>Horarios</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.ciudad}</td>
              <td>{row.direccion}</td>
              <td>{row.diasRecojo}</td>
              <td>{row.horarios}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
