//const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_URL = "https://guerreropromo-production.up.railway.app"

export const sendEmailRequest = () => ({
  type: "SEND_EMAIL_REQUEST",
});

export const sendEmailSuccess = () => ({
  type: "SEND_EMAIL_SUCCESS",
});

export const sendEmailFailure = (error) => ({
  type: "SEND_EMAIL_FAILURE",
  payload: error,
});

export const sendEmail = (emailData) => {
  return async (dispatch) => {
    dispatch(sendEmailRequest());
    try {
      const response = await fetch(`${SERVER_URL}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });
     
      if (response.ok) {
        dispatch(sendEmailSuccess());
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    } catch (error) {
      dispatch(sendEmailFailure(error.toString()));
    }
  };
};
