import React, { useEffect,useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./Yape.module.css";
import { useSelector, useDispatch } from "react-redux";
import { sendEmail } from "../../actions/emailActions";
import { useNavigate } from "react-router-dom";
import axios from "axios";

/* const user = {
  user:{
    nombre: "Franz",
    telefono: 1138771146,
    email: "franzduran.fd@gmail.com"
  }
} */

const Yape = ({ selectedVia }) => {
  const dispatch = useDispatch();
  const emailState = useSelector((state) => state.email);
  const state = useSelector((state) => state.user);
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (localStorage.getItem("devolucion")) {
      console.log("devuelve");
     navigate("/login")
    }
    console.log("no devuelve");
  }, []);

  //-----FORM HOOK --------
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const [envio ,setEnvio] = useState(false)

  const onSubmit = async (data) => {

    if(envio){
      console.log("Se bloquean");
      return
    }else {
      setEnvio(true)
    }


    let code = localStorage.getItem("code");
    console.log("code",code);

    const userData = {
      Nombre: user?.user?.name + "--->" + data.nombre + " // ciudad : " + user?.user?.city  + " // codigo : " + code + " // email: " + user?.user?.email + " // cel: " + user?.user?.phone ,
      email: user.user.email,
      bankType: "no",
      bankAccount: "no",
      cci: "no",
      DNI: user.user.document + "--->" + data.documento,
      premioDevolucion: "Yape" + "--->" + data.telefono,
      Turno: "no",
      premioFisico: "no",
    };

    try {
      const resp = await axios.post(
        "https://guerreropromo-production.up.railway.app/infoGanadores/crearGanadoes",
        userData
      );
      //console.log("resultadoooo", resp);
      localStorage.setItem("devolucion", "si")
      const emailData = {
        nombre: data.nombre,
        telefono: data.telefono,
        email: user.user.email,
        via: selectedVia,
      };
      //console.log("resultadoooo", emailData);
      await dispatch(sendEmail(emailData));

      navigate("/premio-devolucion/gracias");
    } catch (error) {}
  };

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
  };

  return (
    <section className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <input
          type="number"
          name="telefono"
          placeholder="Telefono"
          {...register("telefono", {
            required: true,
            pattern: {
              value: /^[0-9]+$/,
              message: "Solo debe contener números",
            },
          })}
        />
        <input
          type="text"
          name="nombre"
          placeholder="Nombre completo"
          {...register("nombre", {
            required: true,
            pattern: {
              value: /^[a-zA-Z\s]*$/,
              message: "Nombre solo puede contener letras",
            },
          })}
          onInput={handleInput}
        />
        <input
          type="number"
          name="documento"
          placeholder="DNI"
          {...register("documento", {
            required: true,
            pattern: {
              value: /^[0-9]+$/,
              message: "Solo debe contener números",
            },
          })}
        />
        <button
          type="submit"
          disabled={!isValid}
          className={`${styles.button} ${
            (!isValid || emailState.loading === true) && styles.buttonDisabled
          } `}
        >
          {emailState.loading ? "Enviando..." : "Enviar"}
        </button>
      </form>
    </section>
  );
};

export default Yape;
