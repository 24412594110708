import { useWindowSize } from "../../components/hooks.js";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../dashboard/Dashboard.module.css";
import fondoImg from "../../assets/fondo-form.jpg";
import fondoMobile from "../../assets/fondoMobile.jpg";
import image1 from "../../assets/titulo_promo-form.png";
import image1Mobile from "../../assets/logo_promo.png";
import image2 from "../../assets/perrito_premios-form.png";
import image3 from "../../assets/logo_suave.png";
import { legal, terminos } from "../../components/datos";
import "remixicon/fonts/remixicon.css";
import EndRefundPrize from "./EndRefundPrize.jsx";
import Modal from "../../components/modals/ModalTerminos.jsx";

const GraciasDevolucion = () => {
  const size = useWindowSize();
  const isMobile = size.width < 1023;

  // MODAL --------------------------
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(terminos);

  const openModal = () => {
    setShowModal(true);
  };

  const replaceTerminosWithLink = (text) => {
    const parts = text.split("*Aplican términos y condiciones");
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && (
          <span className={styles.link} onClick={openModal}>
            *Aplican términos y condiciones
          </span>
        )}
      </React.Fragment>
    ));
  };

  return (
    <section className={styles.container}>
      {isMobile ? (
        <img src={fondoMobile} alt="img" className={styles.fondoImgMobile} />
      ) : (
        <img src={fondoImg} alt="img" className={styles.fondoImg} />
      )}

      <div className={styles.content}>
        {isMobile && (
          <img src={image3} alt="img" className={styles.logoSuave2} />
        )}
        <div className={styles.imgContainer}>
          <Link to={"/"}>
            <img
              src={isMobile ? image1Mobile : image1}
              alt="img"
              className={styles.image1}
            />
          </Link>
          <img src={image2} alt="img" className={styles.image2} />
        </div>
        <div className={styles.boxContainer}>
          {!isMobile && (
            <img src={image3} alt="img" className={styles.logoSuave} />
          )}
          <EndRefundPrize />
        </div>
      </div>
      <span className={styles.textFooter}>
        {replaceTerminosWithLink(legal)}
      </span>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={modalContent.title}
        content={modalContent}
      />
    </section>
  );
};

export default GraciasDevolucion;
