import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ViaRefundPrize.module.css";
import Yape from "./Yape";
import { useNavigate } from "react-router-dom";
import Transferencia from "./Transferencia";
import { sendEmail } from "../../actions/emailActions";
import axios from "axios";

const banks = ["Banco 1", "Banco 2", "Banco 3"];



const ViaRefundPrize = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedVia, setSelectedVia] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (localStorage.getItem("devolucion")) {
      console.log("devuelve");
     navigate("/login")
    }
    console.log("no devuelve");
  }, []);

  const handleViaChange = (event) => {
    setSelectedVia(event.target.value);
    setSelectedOption("");
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };


  let code = localStorage.getItem("code");

  const [envio ,setEnvio] = useState(false)

  const handleEmailChange = async () => {

    if(envio){
      console.log("Se bloquean");
      return
    }else {
      setEnvio(true)
    }


     const userData = {
      Nombre: user?.user?.name + " // ciudad : " + user?.user?.city + " // codigo : " +  code + " // email: " + user?.user?.email + " // cel: " + user?.user?.phone ,
      email: user.user.email,
      bankType: "no",
      bankAccount: "no",
      cci: "no",
      DNI: user.user.document,
      premioDevolucion: "Yape" + "--->" + user.user.phone,
      Turno: "no",
      premioFisico: "no",
    }; 

    try {
       const resp = await axios.post(
        "https://guerreropromo-production.up.railway.app/infoGanadores/crearGanadoes",
        userData
      );
   
      
      const emailData = {
        nombre: user.user.name,
        telefono: user.user.phone,
        email: user.user.email,
        via: selectedVia,
      };
      console.log("resultadoooo", emailData);
      await dispatch(sendEmail(emailData));

      localStorage.setItem("devolucion", "si");

      navigate("/premio-devolucion/gracias");
    } catch (error) {}
  };

  return (
    <section className={styles.container}>
      <div className={styles.titleContent}>
        <h3 className={styles.title}>¡Ganaste la </h3>
        <h3 className={styles.title}>DEVOLUCIÓN</h3>
        <h3 className={styles.title}>de tu compra!</h3>
      </div>
      <div className={styles.form}>
        <h3 className={styles.text}>
        Para el depósito de tu premio, por favor indícanos la manera de tu preferencia:
        </h3>
        <div className={styles.selectContent}>
          <select name="via" value={selectedVia} onChange={handleViaChange}>
            <option value="">Selecciona</option>
            <option value="Yape/Plin">YAPE/PLIN </option>
            <option value="Transferencia">Transferencia bancaria</option>
          </select>
          <i className="ri-arrow-drop-down-line"></i>
        </div>
        {selectedVia && (
          <>
            <h3 className={styles.text}>
              {selectedVia === "Yape/Plin"
                ? "¿A tu número o al de otra persona?"
                : "¿A tu cuenta o a la de otra persona?"}
            </h3>
            <div className={styles.selectContent}>
              <select
                name="option"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="">Selecciona</option>
                <option value="self">
                  {selectedVia === "Yape/Plin" ? "A tu número" : "A tu cuenta"}
                </option>
                <option value="other">
                  {selectedVia === "Yape/Plin"
                    ? "Al de otra persona"
                    : "A la de otra persona"}
                </option>
              </select>
              <i className="ri-arrow-drop-down-line"></i>
            </div>
          </>
        )}

        {selectedVia === "Yape/Plin" && selectedOption === "other" && (
          <Yape selectedVia={selectedVia} />
        )}
        {selectedVia === "Transferencia" && selectedOption && (
          <Transferencia
            selectedOption={selectedOption}
            selectedVia={selectedVia}
          />
        )}

        {selectedVia === "Yape/Plin" && selectedOption === "self" && (
          <button
            type="submit"
            className={`${styles.button} `}
            onClick={handleEmailChange}
          >
            Enviar
          </button>
        )}
      </div>
    </section>
  );
};

export default ViaRefundPrize;
