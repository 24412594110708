import { useWindowSize } from "../../components/hooks.js";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import styles from "./PhysicalPrize.module.css";
import { useSelector, useDispatch } from "react-redux";
import { sendEmail } from "../../actions/emailActions";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PhysicalPrize = () => {
  const size = useWindowSize();
  const isMobile = size.width < 1023;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailState = useSelector((state) => state.email);
  const [userCity, setUserCity] = useState([]);
  const [horas, setHoras] = useState(["10:00 a 12:00", "11:00 a 12:00"]);
  const [dias, setDias] = useState([]);
  const [envioFecha, setEnvioFecha] = useState({
    fecha: "",
    hora: "",
    lugar: "",
  });

  //-----FORM HOOK --------
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  ////nueva logica////////

  const filterPastDates = (dates) => {
    const today = new Date();
    return dates.filter(dateString => {
      const [day, month, year] = dateString.split("/").map(Number);
      const date = new Date(year, month - 1, day);
      return date >= today;
    });
  };


  const state = useSelector((state) => state);
  const getAgenda = async () => {
    try {

      let Citybody = state?.user?.user?.city;

      // Dividir la cadena por espacios y tomar la primera palabra
      if (Citybody == "Lima y Callao") {
        Citybody = "Lima";
      }

      


      let resp = await axios.put(
        "https://guerreropromo-production.up.railway.app/agenda/getTurnos",{
          ciudad: Citybody,
         // ciudad :"Lima"
        }
      );

      console.log("resp",resp);

      setUserCity(resp.data.data);

      let diasSet =  new Set();
      let horasSet = new Set();

    
      resp.data.data.forEach((el, i) => {
        diasSet.add(el.dia);
        horasSet.add(el.horario);
      });

      setEnvioFecha({
        ...envioFecha,
        lugar: resp.data.data[0].reserv,
      });

   
      let dias = Array.from(diasSet)
      dias = filterPastDates(dias);

dias.shift();
      console.log("dias",dias);
      setDias(dias);
      //console.log("horas", horasSet);
      let horas = Array.from(horasSet);
      setHoras(horas);

      //console.log("resp", resp.data);
    } catch (error) {
      console.error("Error en el registro del usuario:", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("devolucion")) {
      console.log("devuelve");
     navigate("/login")
    }
    console.log("no devuelve");
  }, []);

  

  useEffect(() => {
    getAgenda();
    console.log("Premio Fisico", state);
  }, []);

  const onSubmit = async (data) => {
    try {
      console.log("envio", {
        Nombre: state?.user?.user?.name + " // ciudad : " + state?.user?.user?.city,
        DNI: state?.user?.user?.document,
        premioFisico: state?.premioFisico?.UltimoPremio,
        premioDevolucion: "no",
        Turno:
          envioFecha.hora + " " + envioFecha.fecha + " " + envioFecha.lugar,
        cci: "",
        bankType: "",
        bankAccount: "",
      });

      let code = localStorage.getItem("code");

      console.log("code",code);

      const resp = await axios.post(
        "https://guerreropromo-production.up.railway.app/infoGanadores/crearGanadoes",
        {
          Nombre: state?.user?.user?.name + " // ciudad : " + state?.user?.user?.city + " // codigo: " + code + " // email: " +  state?.user?.user?.email + " // cel: " +  state?.user?.user?.phone ,
          DNI: state?.user?.user?.document,
          premioFisico: state?.premioFisico?.UltimoPremio,
          premioDevolucion: "no",
          Turno:
            envioFecha.hora + " " + envioFecha.fecha + " " + envioFecha.lugar,
          cci: "",
          bankType: "",
          bankAccount: "",
        }
      );

      const emailData = {
        nombre: state?.user?.user?.name,
        email: state?.user.user.email,
        lugar: userCity[0]?.ciudad,
        direccion: userCity[0]?.reserv,
        premio: state?.premioFisico?.UltimoPremio,
        documento: state?.user?.user?.document,
        dia: data.fecha,
        horario: data.hora,
      };
      await dispatch(sendEmail(emailData));
      localStorage.setItem("devolucion", "si")

      navigate("/gracias");
    } catch (error) {
      console.error("Error en el registro del usuario:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnvioFecha({
      ...envioFecha,
      [name]: value,
    });
  };


  function formatPremio(premio) {
    if(!premio) return
    const regex = /^(.+?)(#\d+)?$/;
    const match = premio?.match(regex);
    return match ? match[1] : premio;
  }

  return (
    <section className={styles.container}>
      <div className={styles.titleContent}>
        <h2 className={styles.title}>¡GANASTE UN/UNA</h2>
        <h2 className={styles.title}> {formatPremio(state.premioFisico.UltimoPremio)} !</h2>
      </div>
      <p className={styles.text}>
        Para el recojo de tu premio, por favor acércate a:
      </p>
      <p className={styles.text}>
        Lugar: {userCity[0]?.ciudad} {isMobile ? <br /> : "/"} Dirección:{" "}
        {userCity[0]?.reserv}
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <p>Elige cuándo lo recogerás:</p>
        <div className={styles.selectContainer}>
          <div className={styles.selectContent}>
            <select
              name="fecha"
              {...register("fecha", {
                required: true,
              })}
              value={envioFecha.value}
              onChange={handleChange}
            >
              <option value="">Día</option>
              {dias.map((day, index) => (
                <option key={index} value={day}>
                  {day}
                </option>
              ))}
            </select>
            <i className="ri-arrow-drop-down-line"></i>
          </div>
          <div className={styles.selectContent}>
            <select
              name="hora"
              {...register("hora", {
                required: true,
              })}
              value={envioFecha.hora}
              onChange={handleChange}
            >
              <option value="">Horario</option>
              {horas.map((timeSlot) => (
                <option key={timeSlot} value={timeSlot}>
                  {timeSlot}
                </option>
              ))}
            </select>
            <i className="ri-arrow-drop-down-line"></i>
          </div>
        </div>
        <span>
          Si no puedes recogerlo llena esta{" "}
          <a
            href="/CARTA PODER PROMOCION SUAVE RINDEMAX.docx"
            download
            className={styles.link}
          >
            carta poder
          </a>{" "}
          con los datos de la persona que lo hara por ti
        </span>
        <button
          type="submit"
          disabled={!isValid}
          className={`${styles.button} ${
            (!isValid || emailState.loading === true) && styles.buttonDisabled
          } `}  
        >
          {emailState.loading ? "Agendando..." : "AGENDAR RECOJO"}
        </button>

      </form>
    </section>
  );
};

export default PhysicalPrize;
