import axios from "axios";

// URL del servidor donde se encuentran los datos de las personas
//const URL_SERVER = "https://guerreropromo-production.up.railway.app/persons/";
const URL_SERVER ="http://localhost:3000"

// Acción que indica que se ha iniciado una solicitud de inicio de sesión
export const loginRequest = () => ({
  type: "LOGIN_REQUEST",
});

// Acción que se despacha cuando el inicio de sesión es exitoso
// Recibe el usuario como payload y lo almacena en el estado
export const loginSuccess = (user) => ({
  type: "LOGIN_SUCCESS",
  payload: user,
});

// Acción que se despacha cuando el inicio de sesión falla
// Recibe el error como payload y lo almacena en el estado
export const loginFailure = (error) => ({
  type: "LOGIN_FAILURE",
  payload: error,
});

// Acción que establece el usuario en el estado global
// Recibe el usuario como payload y lo almacena en el estado
export const setUser = (user) => ({
  type: "SET_USER",
  payload: user,
});

// Función asíncrona que realiza el inicio de sesión del usuario
// Recibe el dni como parámetro y despacha las acciones correspondientes
export const loginUser = (dni) => async (dispatch) => {
  // Despacha la acción de solicitud de inicio de sesión
  dispatch(loginRequest());
  try {
    // Realiza una solicitud GET a la URL del servidor con el dni del usuario
    const response = await axios.get(`${URL_SERVER}/persons/${dni}`);
    // Despacha la acción de éxito de inicio de sesión con los datos del usuario recibidos del servidor
    dispatch(loginSuccess(response.data.data));
  } catch (error) {
    // Despacha la acción de falla de inicio de sesión con el mensaje de error
    dispatch(loginFailure(error.message));
  }
};
