import React from "react";
import styles from "./modalTerminos.module.css";

const Modal = ({ show, onClose, content }) => {
  if (!show) {
    return null;
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        {content &&
          (content.title !== "¡Felicitaciones! Ya te encuentras registrad@." ||
            content.title !== "Error en el registro del usuario") && (
            <button className={styles.closeButton} onClick={onClose}>
              &times;
            </button>
          )}
        <h2 className={styles.title}>{content.title}</h2>
        {content.image ? (
          <img src={content.image} alt="picture" className={styles.image} />
        ) : (
          <p className={styles.parrafo}>{content.text}</p>
        )}
      </div>
    </div>
  );
};

export default Modal;
