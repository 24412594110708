import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ auth, codeValid, children }) => {
  console.log(codeValid, children.type.name);
  if (!auth) {
    return <Navigate to="/login" />;
  }

  if (codeValid === false && children.type.name !== "Dashboard") {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default PrivateRoute;
