import React, { useEffect,useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./Transferencia.module.css";
import { useSelector, useDispatch } from "react-redux";
import { sendEmail } from "../../actions/emailActions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { bancos } from "../../components/datos";


/* const user = {
  user: {
    name: "Franz",
    telefono: 1138771146,
    email: "franzduran.fd@gmail.com",
  },
}; */

const Transferencia = ({
  selectedVia,
  selectedOption,
}) => {
  const dispatch = useDispatch();
  const emailState = useSelector((state) => state.email);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  //-----FORM HOOK --------
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (localStorage.getItem("devolucion")) {
      console.log("devuelve");
     navigate("/login")
    }
    console.log("no devuelve");
  }, []);

  const [envio ,setEnvio] = useState(false)

  const onSubmit = async (data) => {

    if(envio){
      console.log("Se bloquean");
      return
    }else {
      setEnvio(true)
    }

    let code = localStorage.getItem("code");
    console.log("code",code);

    const userData = {
      Nombre:
        selectedOption === "self"
          ? user?.user?.name+ " // ciudad : " + user?.user?.city + " // codigo : "  + code + " // email: " + user?.user?.email + " // cel: " + user?.user?.phone 
          : user?.user?.name + "--->" + (data.nombre ? data.nombre : "") + " // ciudad : " + user?.user?.city + " // codigo : " + code + " // email: " + user?.user?.email + " // cel: " + user?.user?.phone,
      email: user.user.email,
      bankType: data.banco,
      bankAccount: data.cuentaBancaria,
      cci: data.cci,
      DNI:
        selectedOption === "self"
          ? user.user.document
          : user.user.document + "--->" + data.documento,
      premioDevolucion: "Transferencia",
      Turno: "no",
      premioFisico: "no",
    };

  
    try {
      const resp = await axios.post(
        "https://guerreropromo-production.up.railway.app/infoGanadores/crearGanadoes",
        userData
      );
      //console.log("dataaaa", resp);
      
      const emailData = {
        nombre: selectedOption === "self" ? user.user.name : data.nombre,
        //telefono: selectedOption === "self" ? user.user.phone : data.telefono,
        email: user.user.email,
        via: selectedVia,
        banco: data.banco,
        cuentaBancaria: data.cuentaBancaria,
        cci: data.cci,
      };
      //console.log("resultadoooo", emailData);
      await dispatch(sendEmail(emailData));
      localStorage.setItem("devolucion", "si")

      navigate("/premio-devolucion/gracias");
    } catch (error) {}
  };

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
  };

  return (
    <section className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {selectedOption === "self" ? (
          <>
            <div className={styles.selectContent}>
              <select
                name="banco"
                {...register("banco", {
                  required: true,
                })}
              >
                <option value="">Selecciona banco</option>
                {bancos.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
              <i className="ri-arrow-drop-down-line"></i>
            </div>
            <input
              type="number"
              name="cuentaBancaria"
              placeholder="Cuenta bancaria"
              {...register("cuentaBancaria", {
                required: true,
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Solo debe contener números",
                },
              })}
            />
            <input
              type="number"
              name="cci"
              placeholder="CCI"
              {...register("cci", {
                required: true,
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Solo debe contener números",
                },
              })}
            />
          </>
        ) : (
          <>
            <input
              type="text"
              name="nombre"
              placeholder="Nombre completo"
              {...register("nombre", {
                required: true,
                pattern: {
                  value: /^[a-zA-Z\s]*$/,
                  message: "Nombre solo puede contener letras",
                },
              })}
              onInput={handleInput}
            />
            <input
              type="number"
              name="documento"
              placeholder="DNI"
              inputMode="numeric"
              {...register("documento", {
                required: true,
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Solo debe contener números",
                },
              })}
            />
            <div className={styles.selectContent}>
              <select
                name="banco"
                {...register("banco", {
                  required: true,
                })}
              >
                <option value="">Selecciona banco</option>
                {bancos.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
              <i className="ri-arrow-drop-down-line"></i>
            </div>
            <input
              type="number"
              name="cuentaBancaria"
              inputMode="numeric"
              placeholder="Número de cuenta"
              {...register("cuentaBancaria", {
                required: true,
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Solo puede contener números",
                },
              })}
            />
            <input
              type="number"
              name="cci"
              placeholder="CCI"
              inputMode="numeric"
              {...register("cci", {
                required: true,
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Solo puede contener números",
                },
              })}
            />
          </>
        )}

        <button
          type="submit"
          disabled={!isValid}
          className={`${styles.button} ${
            (!isValid || emailState.loading === true) && styles.buttonDisabled
          } `}
        >
          {emailState.loading ? "Enviando..." : "Enviar"}
        </button>
      </form>
    </section>
  );
};

export default Transferencia;
