import "./App.css";
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages/home/Home";
import Register from "./pages/register/Register";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard ";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import NoPrize from "./pages/noPrize/NoPrize";
import PremioFisico from "./pages/physicalPrize/PremioFisico";
import Gracias from "./pages/physicalPrize/Gracias";
import PremioDevolucion from "./pages/refundPrize/PremioDevolucion";
import GraciasDevolucion from "./pages/refundPrize/GraciasDevolucion";
import EndPhysicalPrize from "./pages/physicalPrize/EndPhysicalPrize";
import Excel from "./pages/Excel/Excel";

function App() {
  const [auth, setAuth] = useState(true);
  const [codeValid, setCodeValid] = useState(true);

  return (
    <>
      <Helmet>
        <title>SUAVE / PROMO + DE 2000 PREMIOS</title>
        <meta
          name="description"
          content="Encuentra el código al interior del empaque y descubre si ganaste al instante"
        />
        <meta name="keywords" content="Suave, Promo, 2000, premios" />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute auth={auth}>
                <Dashboard setCodeValid={setCodeValid} />
              </PrivateRoute>
            }
          />
          <Route
            path="/no-premio"
            element={
              <PrivateRoute auth={auth} codeValid={codeValid}>
                <NoPrize />
              </PrivateRoute>
            }
          />

          <Route
            path="/premio-fisico"
            element={
              /* <PrivateRoute auth={auth} codeValid={codeValid}> */
              <PremioFisico />
              /* </PrivateRoute> */
            }
          />
          <Route
            path="/premio-fisico/gracias"
            element={
              /* <PrivateRoute auth={auth} codeValid={codeValid}> */
              <Gracias />
              /* </PrivateRoute> */
            }
          />
          <Route path="/premio-devolucion" element={<PremioDevolucion />} />
          <Route
            path="/premio-devolucion/gracias"
            element={<GraciasDevolucion />}
          />

          <Route
            path="/gracias"
            element={
              <Dashboard setCodeValid={setCodeValid} ganadoresRegister={true}>
                <EndPhysicalPrize></EndPhysicalPrize>
              </Dashboard>
            }
          />

          <Route path="/Excel" element={<Excel></Excel>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
