const initialState = {
    user: null,
  };
  
  const userReducer = (state = initialState, action) => {
    console.log("action",action);
    switch (action.type) {
      case "SET_USER":
        console.log("set user");
        return {
          ...state,
          user: action.payload,
        };
      case "CLEAR_USER":
        return {
          ...state,
          user: null,
        };
      default:
        return state;
    }
  };
  
  export default userReducer;
  