import { useWindowSize } from "../../components/hooks.js";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import fondoImg from "../../assets/fondo-form.jpg";
import fondoMobile from "../../assets/fondoMobile.jpg";
import image1 from "../../assets/titulo_promo-form.png";
import image1Mobile from "../../assets/logo_promo.png";
import image2 from "../../assets/perrito_premios-form.png";
import image3 from "../../assets/logo_suave.png";
import { legal, terminos } from "../../components/datos";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setUser } from "../../actions/authActions";
import Modal from "../../components/modals/ModalTerminos.jsx";

/* const user = [
  { name: "Franz", documento: 123456789 },
  { name: "Alejandro", documento: 987654321 },
]; */

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const size = useWindowSize();
  const isMobile = size.width < 1023;

  const { loading, error } = useSelector((state) => state.auth);

  const [errorMensaje, setErrorMensaje] = useState("");

  //-----FORM HOOK --------
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const [valuePerson, setValuePerson] = useState({
    Dni: "",
  });

  useEffect(() => {
    const local = localStorage.getItem("fechaGanador");
    const newDate = new Date();
    //console.log(local.toString().split(" ").slice(0, 3).join(" "));
    //console.log(newDate.toString().split(" ").slice(0, 3).join(" "));
    if (
      local &&
      local.toString().split(" ").slice(0, 3).join(" ") !==
        newDate.toString().split(" ").slice(0, 3).join(" ")
    ) {
      //console.log("entro al if--------------");
      localStorage.setItem("fechaGanador", "");
      localStorage.setItem("devolucion", "")

    }
  }, []);

  const onSubmit = async (data) => {
    try {
      //console.log("dni", valuePerson.Dni);
      const resp = await axios.get(
        "https://guerreropromo-production.up.railway.app/persons/" +
          valuePerson.Dni
      );

      //console.log("resp", resp.data.data);

      if (resp.data.data == null || !resp.data.data) {
        setErrorMensaje("El documento ingresado no se encuentra registrado");
        return;
      }

      dispatch(setUser(resp.data.data));

      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      setErrorMensaje("El documento ingresado no se encuentra registrado");
    }
    dispatch({ type: "SET_DNI", payload: valuePerson.Dni });
  };

  useEffect(() => {
    if (error) {
      setErrorMensaje("¡El DNI que ingresaste no está registrado!");
    }
  }, [error]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "Dni") {
      const numbersOnly = value.replace(/[^0-9]/g, "");
      setValuePerson({
        Dni: numbersOnly,
      });
    }
  };

  // MODAL --------------------------
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
  });

  const openModalWithContent = (type) => {
    const content = type === "terminos" ? terminos : "";
    setModalContent({ content });
    setShowModal(true);
  };

  const replaceTerminosWithLink = (text) => {
    const parts = text.split("*Aplican términos y condiciones");
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && (
          <span
            className={styles.link}
            onClick={() => openModalWithContent("terminos")}
          >
            *Aplican términos y condiciones
          </span>
        )}
      </React.Fragment>
    ));
  };

  return (
    <section className={styles.container}>
      {isMobile ? (
        <img src={fondoMobile} alt="img" className={styles.fondoImgMobile} />
      ) : (
        <img src={fondoImg} alt="img" className={styles.fondoImg} />
      )}

      <div className={styles.content}>
        {isMobile && (
          <img src={image3} alt="img" className={styles.logoSuave2} />
        )}
        <div className={styles.imgContainer}>
          <Link to={"/"}>
            <img
              src={isMobile ? image1Mobile : image1}
              alt="img"
              className={styles.image1}
            />
          </Link>
          <img src={image2} alt="img" className={styles.image2} />
        </div>
        <div className={styles.boxContainer}>
          {!isMobile && (
            <img src={image3} alt="img" className={styles.logoSuave} />
          )}
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.inputContent}>
              <input
                type="text"
                name="Dni"
                placeholder="Ingresa tu DNI / C.E."
                value={valuePerson.Dni}
                {...register("Dni", {
                  required: true,
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "DNI solo puede contener números",
                  },
                })}
                onChange={handleChange}
              />
              {errorMensaje && (
                <span className={styles.error}>{errorMensaje}</span>
              )}
              {errorMensaje && (
                <span>
                  <Link to="/register" className={styles.linkRegister}>
                    Registrarse
                  </Link>
                </span>
              )}
              <span
                className={styles.link}
                onClick={() => openModalWithContent("terminos")}
              >
                Ver Términos y Condiciones
              </span>
            </div>
            <button
              type="submit"
              disabled={!isValid || loading}
              className={`${styles.button} ${
                (!isValid || loading) && styles.buttonDisabled
              } `}
            >
              {loading ? "Cargando..." : "Validar"}
            </button>
          </form>
        </div>
      </div>

      <span className={styles.textFooter}>
        {replaceTerminosWithLink(legal)}
      </span>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={modalContent.title}
        content={modalContent.content}
      />
    </section>
  );
};

export default Login;
