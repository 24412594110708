import React from "react";
import styles from "./EndRefundPrize.module.css";
import { useNavigate } from "react-router-dom";

const EndRefundPrize = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/dashboard");
  };

  return (
    <section className={styles.container}>
      <div className={styles.titleContent}>
        <h2 className={styles.title}>¡Tu devolución está programada!</h2>
        <p className={styles.text}>
          Te enviamos un correo con los datos de la devolución, si no lo
          encuentras en tu bandeja de entrada, revisa tu bandeja de spam.
        </p>
        <p className={styles.text}>
        Sigue participando comprando más productos Suave de la promoción{" "}
          <span className={styles.textNegrita}>
            ¡son miles de premios y otro puede ser tuyo!
          </span>
        </p>
      </div>

      <button type="submit" className={styles.button} onClick={handleSubmit}>
        INGRESA OTRO CÓDIGO
      </button>
    </section>
  );
};

export default EndRefundPrize;
