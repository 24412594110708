import { useWindowSize } from "../../components/hooks.js";
import React, {useState,useEffect} from "react";
import { Link } from "react-router-dom";
import styles from "./Home.module.css";
import fondo from "../../assets/fondo.jpg";
import fondoMobile from "../../assets/fondoMobile.jpg";
import image1 from "../../assets/logo_promo.png";
import image2 from "../../assets/perrito_premios.png";
import image3 from "../../assets/logo_suave.png";
import { legal, terminos } from "../../components/datos";
import Modal from "../../components/modals/ModalTerminos.jsx";
import ReactGA from 'react-ga';

const Home = () => {
  const size = useWindowSize();
  const isMobile = size.width < 1023;


    useEffect(() => {
      ReactGA.pageview('/home');
    },[])

  // MODAL --------------------------
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(terminos);

  const openModal = () => {
    setShowModal(true);
  };

  const replaceTerminosWithLink = (text) => {
    const parts = text.split("*Aplican términos y condiciones");
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && (
          <span className={styles.link} onClick={openModal}>
            *Aplican términos y condiciones
          </span>
        )}
      </React.Fragment>
    ));
  };

  return (
    <section className={styles.container}>
      {isMobile ? (
        <img src={fondoMobile} alt="img" className={styles.fondoImgMobile} />
      ) : (
        <img src={fondo} alt="img" className={styles.fondoImg} />
      )}

      <div className={styles.content}>
        {isMobile && <img src={image3} alt="img" className={styles.image3} />}
        <div className={styles.block}>
          <div className={styles.imgContainer}>
            <img src={image1} alt="img" className={styles.image1} />
            <img src={image2} alt="img" className={styles.image2} />
          </div>
          <div className={styles.boxContainer}>
            {!isMobile && (
              <img src={image3} alt="img" className={styles.image3} />
            )}
            <div className={styles.boxs}>
              <div className={styles.box}>
                <h4>PASO 1</h4>
                <p>Encuentra tu código al interior del empaque</p>
              </div>
              <span className={styles.linea} />
              <div className={styles.box}>
                <h4>PASO 2</h4>
                <p>Ingrésalo aquí</p>
              </div>
              <span className={styles.linea} />
              <div className={styles.box}>
                <h4>PASO 3</h4>
                <p>Participa y gana</p>
                <Link to="/login">
                  <button>PARTICIPA AQUÍ</button>
                </Link>

                <span>
                  Si vienes por primera vez,{" "}
                  <Link to="/register" className={styles.link}>
                    regístrate aquí
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className={styles.parrafoTerminos}>{replaceTerminosWithLink(legal)}</p>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={modalContent.title}
        content={modalContent}
      />
    </section>
  );
};

export default Home;
