// Reducer para manejar el estado de guardado de formularios
export const formSaveReducer = (state = {}, action) => {
  switch (action.type) {
    // Acción que indica que se ha iniciado una solicitud para guardar el formulario
    case "FORM_SAVE_REQUEST":
      return { loading: true }; // Establece loading en true

    // Acción que indica que el guardado del formulario fue exitoso
    case "FORM_SAVE_SUCCESS":
      return { loading: false, success: true, data: action.payload }; // Establece loading en false, success en true y almacena los datos devueltos en data

    // Acción que indica que el guardado del formulario falló
    case "FORM_SAVE_FAIL":
      return { loading: false, success: false, error: action.payload }; // Establece loading en false y almacena el mensaje de error en error

    // Acción por defecto: devuelve el estado actual sin cambios
    default:
      return state;
  }
};
