import React, { useState, useEffect } from "react";
import styles from './Excel.module.css';
import * as XLSX from 'xlsx';
import axios from "axios";
//  import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Excel = () => {
  const [Codes, setCodes] = useState("");
  const [Participantes, setParticipantes] = useState([]);

  const cargarDatosDesdeExcel = (archivoExcel) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const sheetName = workbook.SheetNames[0]; // Suponemos que estamos interesados en la primera hoja

      const worksheet = workbook.Sheets[sheetName];

      // Convierte el contenido de la hoja de cálculo en un arreglo de objetos
      const datos = XLSX.utils.sheet_to_json(worksheet, { header: ['codigo'] });

      // Aquí puedes hacer lo que desees con los datos, como almacenarlos en un estado
      setCodes(datos);
      console.log(Codes)
    };

    reader.readAsBinaryString(archivoExcel);
  };

  const subirCodigos = async () => {
    try {

        console.log("codes",Codes);
     
      //const response = await axios.post("http://localhost:3005/codes", Codes);
      //console.log(response)
      // Restablece el estado y los datos cargados
      setCodes([]);

    } catch (error) {
      console.error("Error al subir los códigos:", error);
    }  
  };

  const subirCodigosTexto = async () => {
    try {

       console.log("codes",Codes);

       const response = await axios.post("http://localhost:3000/codes", Codes);


       console.log("response",response);

  //   console.log(Codes[0])
  // console.log(objetosCodigos[0])
    } catch (error) {
      console.error("Error al subir los códigos:", error);
    }
  };


  


  const excelGanadores = async () => {
    try {
      const response = await axios.get("https://guerreropromo-production.up.railway.app/infoGanadores/obtenerGanadoes");
      //const response = await axios.get("http://localhost:3000/persons/totalUsuarios");
      const data = response.data.data;

  // Filtrar los datos que no tienen createdAt y updatedAt
  const filteredData = data.filter(item => item.createdAt && item.updatedAt);

  // Ordenar los datos por fecha
  const sortedData = filteredData.sort((a, b) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateA - dateB;
  });

  // Remover las horas, dejando solo las fechas
  const formattedData = sortedData.map(item => ({
    ...item,
    createdAt: item.createdAt.split('T')[0],
    updatedAt: item.updatedAt.split('T')[0]
  }));

   
    
      console.log("formattedData" , formattedData);

      // Extrae los datos de la respuesta
      const participantes = formattedData;
  
      // Crear un libro de Excel
      const workbook = XLSX.utils.book_new();
      
      // Crear una hoja de Excel
      const worksheet = XLSX.utils.json_to_sheet(participantes);
      
      // Agregar la hoja al libro
      XLSX.utils.book_append_sheet(workbook, worksheet, "Participantes");
  
      // Guardar el libro como archivo Excel
      XLSX.writeFile(workbook, "participantes.xlsx");
  
    } catch (error) {
      console.error("Error", error);
    }
  };



  const infoIncompleta = async () => {
    try {
      const response = await axios.get("https://guerrerodatosuser-production.up.railway.app/users");
      //const response = await axios.get("http://localhost:3000/persons/totalUsuarios");
      const data = response.data;

      console.log("data",data, response);



      // Extrae los datos de la respuesta
      const participantes = data;
  
      // Crear un libro de Excel
      const workbook = XLSX.utils.book_new();
      
      // Crear una hoja de Excel
      const worksheet = XLSX.utils.json_to_sheet(participantes);
      
      // Agregar la hoja al libro
      XLSX.utils.book_append_sheet(workbook, worksheet, "Participantes");
  
      // Guardar el libro como archivo Excel
      XLSX.writeFile(workbook, "participantes.xlsx");
  
    } catch (error) {
      console.error("Error", error);
    }
  };


  return (
    <div className={styles.divAdmin}>
     {/*  <input type="file" className={styles.inputSubirExcel} onChange={(e) => cargarDatosDesdeExcel(e.target.files[0])} />
      <br></br>
      <input type="file" className={styles.inputSubirTexto} onChange={(e) => cargarDatosDesdeTexto(e.target.files[0])} />
      <button onClick={subirCodigosTexto}>Subir Codigos</button>
      <button onClick={subirCodigosTexto}>Subir Codigos texto</button>*/}
      <button onClick={excelGanadores}>descargar</button>
      <button onClick={infoIncompleta}>Total de premios fisicos</button>
      <table id="table-to-xls" >
        {/* <thead>
          <tr>
            <th>Nombre</th>
            <th>DNI</th>
            <th>Provincia</th>
            <th>Email</th>
            <th>Teléfono</th>
            <th>Puntos</th>
            <th>Dirección</th>

          </tr> */}
        {/* </thead> */}
        {/* <tbody> 
          {Participantes?.map((item) => (
            <tr key={item.id}>
              <td>{item.nombre}</td>
              <td>{item.DNI}</td>
              <td>{item.Provincia}</td>
              <td>{item.email}</td>
              <td>{item.tel}</td>
              <td>{item.puntos}</td>
              <td>{item.direccion}</td>
            </tr>
          ))}*/}
        {/* </tbody> */}
      </table>

{/* 
      <ReactHTMLTableToExcel
                    id="table-to-xls"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
            */}
    </div>
  );
};

export default Excel;