import Table from "../table/Table";
import styles from "./terminos.module.css"
import { table } from "../datos";

export default function Terminos() {
  return (
    <section className={styles.container} >
      <h3 className={styles.title}>1. Empresa Organizadora</h3>
      <p>
        La promoción “Promo Suave Rindemax” en adelante, la “Promoción” es
        organizada por la empresa Kimberly-Clark Perú S.R.L. (en adelante,
        “KIMBERLY”) y se rige por los siguientes Términos y Condiciones (en
        adelante, “T&C”).
      </p>
      <h3 className={styles.title}>2. Vigencia</h3>
      <p>
        La Promoción tiene validez desde el 20 de junio 2024 hasta el 30 de
        septiembre de 2024 o hasta agotar el stock de premios disponibles. Este
        Plazo de Vigencia es improrrogable, no aceptándose en consecuencia
        participaciones posteriores a la finalización de este.
      </p>
      <h3 className={styles.title}>3. Ámbito Geográfico</h3>
      <p>
        La Promoción respecto a los premios físicos sujetos a recojo es válida y
        aplicable solo en las ciudades de Lima, Piura, Chiclayo, Trujillo,
        Chimbote, Huacho, Ica, Arequipa, Tacna, Cajamarca, Huánuco, Huancayo,
        Ayacucho, Cusco, Juliaca, Iquitos, Pucallpa y la Provincia
        Constitucional del Callao. <br /> La Promoción respecto a los premios de
        reembolso del valor del producto (devolución de compra), la Promoción es
        válida y aplicable a nivel nacional.
      </p>
      <h3 className={styles.title}>4. Participantes</h3>
      <p>
        Podrán participar en la Promoción las personas que cumplan las
        siguientes condiciones:
      </p>
      <ul>
        <li>
          ● Personas naturales con Documento Nacional de Identidad (DNI) o
          Carnet de Extranjería (CE) vigentes.
        </li>
        <li>● Mayores de edad.</li>
        <li>
          ● Cumplan con todas y cada una de las condiciones detalladas en la
          mecánica de la Promoción.
        </li>
      </ul>
      <p>
        Para la Promoción respecto a los premios de reembolso del valor del
        producto (devolución de compra), los participantes deberán tener
        residencia legal y domicilio dentro del territorio nacional. Asimismo,
        los participantes deberán ser titulares de una cuenta Yape, Plin o
        cuenta bancaria; en caso el participante no disponga de alguna de estas
        tres opciones para hacer efectivo el premio, deberá solicitar que se
        realice el abono a nombre de un tercero que sí sea titular de una cuenta
        Yape, Plin o cuenta bancaria.
        <br />
        Adicional, para la Promoción respecto a los premios físicos sujetos a
        recojo, los participantes deberán tener residencia legal y domicilio en
        las ciudades de Lima, Piura, Chiclayo, Trujillo,Chimbote, Huacho, Ica,
        Arequipa, Tacna, Cajamarca, Huánuco, Huancayo, Ayacucho, Cusco, Juliaca,
        Iquitos, Pucallpa y la Provincia Constitucional del Callao.
        <br/>
        No podrán participar las personas que se encuentren contemplados en la siguiente condición:  
         <br/>

      <ul>
          <li>
          ●   Ventas mayoristas y convenios.
          </li>

          <li>
          ●   Trabajadores de Kimberly, sus agencias de publicidad y/o promoción, así tampoco sus familiares directos hasta el segundo grado de consanguinidad y/o afinidad o los convivientes de aquellas personas detalladas anteriormente.
          </li>
        </ul>
      </p>
      <h3 className={styles.title}>5. Mecánica de Participación</h3>
      <ul>
        <li>
          a. Realizar la compra mínima de 01 empaque de papel higiénico Suave
          Rindemax presentación x4 unidades marcado con la Promoción.
        </li>
        <li>
          b. Registrarse en la web www.promosuave.com (en adelante, la
          “Plataforma”), completando todos los datos solicitados e ingresar el
          código alfanúmerico que se encuentra impreso en el interior del
          empaque.{" "}
        </li>
        <li>
          c. Una vez ingresado el código, la web brindará el resultado al
          instante a través de un mensaje que indique el premio obtenido (en
          caso resulte ganador) o, de lo contrario (en caso no resulte ganador)
          se le invitará a seguir participando.
        </li>
      </ul>
      <h3 className={styles.title}>6. Premios</h3>
      <ul>
        <li>A. Premios Físicos sujetos a Recojo:</li>
        <span>1. Cuatro (04) televisores 65”.</span>
        <span>2. Cinco (05) celulares.</span>
        <span>3. Cincuenta (50) camisetas oficiales de Perú.</span>
        <span>4. Cien (100) pelotas de fútbol</span>
        <span>
          5. Cincuenta (50) premios de un (01) año de Suave Rindemax gratis
          (equivalente a 36 rollos de Suave Rindemax 1).
        </span>
        <li>
          B. Premios de Reembolso de Valor del Producto (Devolución de Compra):
          Mil Ochocientos (1,800) premios de S/ 7.50 (Siete con 50/100 Soles)
          equivalente al precio de venta sugerido de un empaque de Suave
          Rindemax x4 rollos, que serán abonados a través de transferencia
          bancaria, Yape o Plin.
        </li>
      </ul>
      <p className={styles.title}>
        Los premios son intransferibles e inalienables, no podrán ser cambiados
        por otros, ni podrán ser devueltos. Los premios físicos sujetos a recojo
        no son susceptibles de ser canjeados por dinero. Una vez entregado el
        premio al ganador, éste asume el riesgo de su uso o pérdida, liberando a
        KIMBERLY de toda responsabilidad.
      </p>
      <h3 className={styles.title}>7. Entrega de Premios</h3>
      <p>
        En el caso de los premios físicos sujetos a recojo, la entrega se
        realizará en cualquiera de los puntos de recojo y horarios establecidos
        en el Anexo N° 1 denominado “Puntos de Recojo (Direcciones, Fechas y
        Horarios)”. El ganador deberá seleccionar en la Plataforma el horario y
        punto de recojo de su preferencia. Para recibir el premio, los ganadores
        deberán presentar de manera obligatoria su DNI o CE vigente en físico y
        el empaque con el código ganador que registraron en la Plataforma. En
        caso el ganador no pueda recoger el premio personalmente, podrá delegar
        a un tercero a través de una carta poder que lo autorice. Si el ganador 
        no proporciona los datos solicitados en la Plataforma para el recojo, o 
        él ni el tercero autorizado no recoge el premio en la fecha y horario elegido, 
        perderá el premio de forma automática sin lugar a reclamo, en consecuencia,
         KIMBERLY podrá disponer del premio. <br /> En el caso de
        los premios de reembolso de valor del producto (devolución de compra),
        la entrega se realizará vía Yape, Plin o transferencia bancaria. El
        ganador deberá seleccionar en la Plataforma la modalidad de su
        preferencia y deberá proporcionar los datos para el abono respectivo,
        incluyendo y según corresponda: el número de celular asociado a la
        cuenta Yape o Plin, nombres y apellidos del titular, documento de
        identidad, entidad y cuenta bancaria (regular y CCI). El abono se
        realizará en un plazo máximo de 24 horas en el caso de Yape y/o Plin y
        de 48 horas en el caso de transferencia bancaria, ambos bajo la
        descripción “PROMO SUAVE”. En caso el ganador no proporcione los datos
        solicitados en la Plataforma para el abono, perderá el premio de forma
        automática sin lugar a reclamo, en consecuencia, KIMBERLY podrá disponer
        del premio.


        
      </p>
      <h3 className={styles.title}>8. Autorización de Datos Personales</h3>
      <p>
        El envío de los datos por parte de los participantes de la Promoción es
        voluntario y es un requisito necesario para participar. Los datos
        personales proporcionados serán conservados en nuestro Banco de Datos
        (Eventos Promocionales), el cual se encuentra ubicado en nuestro
        domicilio. Los datos recopilados serán considerados y tratados como
        información confidencial y se aplicarán las medidas de seguridad
        implementadas por Kimberly-Clark Perú S.R.L. y podrán ser tratados de
        manera directa, y/o a través de la agencia implementadora de la
        Promoción, Valor Socios de Marcas S.A.C., en atención de los
        requerimientos establecidos en la normativa de la materia para asegurar
        su debido tratamiento. Los datos suministrados serán conservados por un
        plazo máximo de un (1) año y podrán ser empleados con la finalidad de
        registrarlo como participante de la Promoción, así como para revisar o
        analizar el cumplimiento de los Términos y Condiciones, y/o contactarnos
        con usted en caso de que resulte ganador de alguno de los premios.{" "}
        <br />
        En caso resulte ganador, Kimberly-Clark Perú S.R.L. podrá compartir y
        encargar el tratamiento de su información personal a terceros que
        prestan servicios para la correcta ejecución de la Promoción. En estos
        casos, Kimberly-Clark Perú S.R.L. garantiza que el tratamiento de sus
        datos se limite a las finalidades autorizadas, que se mantenga
        confidencial y se implementen las medidas de seguridad adecuadas. <br />
        Las empresas terceras que realizarán el tratamiento de sus datos
        personales en caso usted resulte ganador se encuentran detalladas en el
        Aviso de Privacidad de la Plataforma. <br />
        Kimberly-Clark Perú S.R.L. informa a los participantes que pueden
        ejercer los derechos de acceso, rectificación, cancelación y oposición,
        así como otros derechos contemplados en la Ley N° 29733, por medio de la
        presentación de una solicitud por escrito y documento que acredite su
        identidad o representación legal en Av. Paseo de la República N° 5895,
        int. 301-302, Miraflores, provincia y departamento de Lima. El registro
        y participación en la Promoción implicará el otorgamiento por parte del
        Participante, del consentimiento previsto en la Ley N° 29733.
      </p>
      <h3 className={styles.title}>9. Condiciones Generales</h3>
      <ul>
        <li>
          ● La sola participación en la Promoción implica, para todos los
          efectos legales, el conocimiento y aceptación de estos Términos y
          Condiciones y el reconocimiento de las decisiones que KIMBERLY tome
          con relación a la Promoción tendrán carácter de definitivas e
          inapelables.
        </li>
        <li>
          ● KIMBERLY se reserva el derecho de establecer y pronunciarse sobre
          aquellas situaciones o circunstancias que no estén expresamente
          previstas en estos Términos y Condiciones, reservándose asimismo el
          derecho de cancelar, suspender o modificar la Promoción por
          circunstancias no previstas que sean ajenas a la voluntad de KIMBERLY,
          aclarándose que, cualquiera de las situaciones mencionadas será
          ajustada a la legislación vigente en la materia.
        </li>
        <li>
          ● KIMBERLY se reserva el derecho, a su entera discreción, de no
          considerar a cualquier participante que (i) no respete los términos y
          condiciones contenidos en el presente documento, (ii) haya interferido
          indebidamente en el procedimiento de la Promoción a través de algún
          ataque o intento de alteración o manipulación al sistema informático o
          al sitio web o al lugar en el cual se encuentre radicado el mismo, o
          (iii) intervenga el sistema de la Promoción bajo cualquier método.
        </li>
        <li>
          ● Los ganadores autorizan a KIMBERLY para que pueda usar su nombre,
          imagen y voz con fines publicitarios – comerciales relacionados con
          esta promoción y/o en cualquier otra publicidad de carácter comercial
          de KIMBERLY, a través de medios impresos, digitales, de audio o video.
          En todo caso, el ganador podrá revocar en cualquier momento dicha
          autorización enviando un documento por escrito, acompañado de una
          fotocopia de su DNI o CE a Kimberly-Clark Perú S.R.L.
        </li>
        <li>
          ● KIMBERLY no se hará cargo de ningún gasto extra y/o adicional que el
          participante pueda reclamar, incluyendo, pero no limitándose a
          pasajes, cargos bancarios, entre otros.
        </li>
      </ul>
      <Table data={table} />
    </section>
  );
}
