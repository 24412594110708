import { useWindowSize } from "../../components/hooks.js";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "../../components/modals/ModalTerminos";
import styles from "./Register.module.css";
import fondoImg from "../../assets/fondo-form.jpg";
import fondoMobile from "../../assets/fondoMobile.jpg";
import image1 from "../../assets/titulo_promo-form.png";
import image1Mobile from "../../assets/logo_promo.png";
import image2 from "../../assets/perrito_premios-form.png";
import image3 from "../../assets/logo_suave.png";
import { useSelector } from "react-redux";
import {
  terminos,
  legal,
  avisoPrivacidad,
  confirmacion,
  departamentos,
  ciudades,
} from "../../components/datos";
import axios from "axios";

const Form = () => {
  const size = useWindowSize();
  const isMobile = size.width < 1023;
  const navigate = useNavigate();
  const formSaveState = useSelector((state) => state.formSave);

  //-----FORM HOOK --------
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  console.log("valid", isValid, "error", errors);

  // MODAL --------------------------
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
  });

  const [departamento, setDepar] = useState();
  const [cities, setCities] = useState([]);

  const onSubmit = async (data) => {
    //console.log(data)
    try {
      console.log("depa", departamento);
      const obj = data;

      obj.state = departamento;

      if (obj.city == "Iquitos") obj.city = "iquitos";

      //console.log("departamento", obj);

      let resp = await axios.post(
        "https://guerreropromo-production.up.railway.app/persons/",
        obj
      );

      if (resp.status) {
        console.log("resp info axios", resp);
        openModalWithContent("registro-ok");

        setTimeout(() => {
          setShowModal(false);
          navigate("/login");
        }, 2500);
      } else {
        //MENSAJE DE ERROR
        console.log(formSaveState.error);
      }
    } catch (error) {
      openModalWithContent("registro-error");
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
      console.error("Error en el registro del usuario:", error);
    }
  };

  const openModalWithContent = (type) => {
    const content =
      type === "terminos"
        ? terminos
        : type === "politicas"
        ? avisoPrivacidad
        : type === "registro-ok"
        ? confirmacion.ok
        : type === "registro-error"
        ? confirmacion.error
        : "";
    setModalContent({ content });
    setShowModal(true);
  };

  const formatString = (input) => {
    // Usamos una expresión regular para insertar un espacio antes de cada letra mayúscula que no esté al principio de la cadena
    const result = input.replace(/([A-Z])/g, " $1").trim();
    return result;
  };

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
  };

  const handleDepartmentChange = (e) => {
    const department = e.target.value;
    if (department == "La Libertad") {
      setCities(ciudades["LaLibertad"] || []);
      setDepar(department);
      return;
    }
    if (department == "Madre De Dios") {
      setCities(ciudades["MadreDeDios"] || []);
      setDepar(department);
      return;
    }
    if (department == "San Martín") {
      setCities(ciudades["SanMartín"] || []);
      setDepar(department);
      return;
    }
    setDepar(department);
    setCities(ciudades[department] || []);
  };

  return (
    <section className={styles.container}>
      {isMobile ? (
        <img src={fondoMobile} alt="img" className={styles.fondoImgMobile} />
      ) : (
        <img src={fondoImg} alt="img" className={styles.fondoImg} />
      )}

      <div className={styles.content}>
        {isMobile && (
          <img src={image3} alt="img" className={styles.logoSuave2} />
        )}
        <div className={styles.imgContainer}>
          <Link to={"/"}>
            <img
              src={isMobile ? image1Mobile : image1}
              alt="img"
              className={styles.image1}
            />
          </Link>
          <img src={image2} alt="img" className={styles.image2} />
        </div>
        <div className={styles.formContainer}>
          {!isMobile && (
            <img src={image3} alt="img" className={styles.logoSuave} />
          )}
          <h3 className={styles.titleForm}>REGISTRO</h3>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.columnContainer}>
              <div className={styles.formColumn}>
                <div className={styles.inputContent}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Nombre completo"
                    {...register("name", {
                      required: true,
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message: "Nombre solo puede contener letras",
                      },
                      validate: {
                        twoWords: (value) =>
                          value.trim().split(/\s+/).length >= 2 ||
                          "Agrega nombre y apellido",
                      },
                    })}
                    onInput={handleInput}
                  />
                  {errors.name && (
                    <span className={styles.error}>{errors.name.message}</span>
                  )}
                </div>
                <div className={styles.selectContent}>
                  <select
                    name="documentType"
                    {...register("documentType", {
                      required: true,
                    })}
                  >
                    <option value="" hidden>
                      Tipo de documento
                    </option>
                    <option value="DNI">DNI</option>
                    <option value="Carné de Extranjería">
                      Carné de Extranjería
                    </option>
                  </select>
                  <i className="ri-arrow-drop-down-line"></i>
                </div>

                {!isMobile && (
                  <div className={styles.selectContent}>
                    <select name="state" onChange={handleDepartmentChange}>
                      <option key={"index"} value="" hidden>
                        Departamento
                      </option>

                      {departamentos.map((department, index) => (
                        <option key={index} value={department}>
                          {department}
                        </option>
                      ))}
                    </select>
                    <i className="ri-arrow-drop-down-line"></i>
                  </div>
                )}
                {isMobile && (
                  <div className={styles.inputContent}>
                    <input
                      type="number"
                      name="document"
                      inputMode="numeric"
                      placeholder="Nro. documento"
                      {...register("document", {
                        required: true,
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Solo debe contener números",
                        },
                      })}
                    />
                  </div>
                )}

                <div className={styles.inputContent}>
                  <input
                    type="number"
                    name="phone"
                    placeholder="Telefono"
                    inputMode="numeric"
                    {...register("phone", {
                      required: true,
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Solo puede contener números",
                      },
                    })}
                  />
                </div>
              </div>
              <div className={styles.formColumn}>
                <div className={styles.inputContent}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Correo electrónico"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^\S+@\S+\.\S+$/,
                        message: "Correo electrónico no es válido",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className={styles.error}>{errors.email.message}</span>
                  )}
                </div>

                {isMobile && (
                  <div className={styles.selectContent}>
                    <select name="state" onChange={handleDepartmentChange}>
                      <option key={"index"} value={"department"} hidden>
                        Departamento
                      </option>

                      {departamentos.map((department, index) => (
                        <option key={index} value={department}>
                          {formatString(department)}
                        </option>
                      ))}
                    </select>
                    <i className="ri-arrow-drop-down-line"></i>
                  </div>
                )}
                {!isMobile && (
                  <div className={styles.inputContent}>
                    <input
                      type="number"
                      inputMode="numeric"
                      name="document"
                      placeholder="Nro. documento"
                      {...register("document", {
                        required: true,
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Solo debe contener números",
                        },
                      })}
                    />
                  </div>
                )}

                <div className={styles.selectContent}>
                  <select
                    name="city"
                    {...register("city", {
                      required: true,
                    })}
                  >
                    <option key={""} value={""} hidden>
                      Ciudad
                    </option>
                    {cities?.map((city, index) => {
                      return (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      );
                    })}
                  </select>
                  <i className="ri-arrow-drop-down-line"></i>
                </div>
                <div className={styles.parrafoTerminos}>
                  <input
                    type="checkbox"
                    name="aceptoTerminos"
                    {...register("aceptoTerminos", {
                      required: true,
                    })}
                  />
                  <p>
                    Acepto los{" "}
                    <span
                      className={styles.link}
                      onClick={() => openModalWithContent("terminos")}
                    >
                      Términos y Condiciones
                    </span>{" "}
                    y{" "}
                    <span
                      className={styles.link}
                      onClick={() => openModalWithContent("politicas")}
                    >
                      Aviso de Privacidad de la promoción{" "}
                    </span>{" "}
                    así como la{" "}
                    <Link
                      to={
                        "https://www.kimberly-clark.com/es-us/global-privacy-policy"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.link}
                    >
                      Política de Privacidad
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
            <button
              type="submit"
              disabled={!isValid || formSaveState.loading}
              className={`${styles.button} ${
                (!isValid || formSaveState.loading) && styles.buttonDisabled
              } `}
              onClick={onSubmit}
            >
              {formSaveState.loading ? "Enviando..." : "Crear cuenta"}
            </button>
          </form>
        </div>
      </div>
      <span className={styles.textFooter}>{legal}</span>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={modalContent.title}
        content={modalContent.content}
      />
    </section>
  );
};

export default Form;
