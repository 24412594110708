// Estado inicial del reducer de autenticación
const initialState = {
  loading: false, // Indicador de carga
  user: null, // Información del usuario autenticado
  error: null, // Mensaje de error en caso de fallo de autenticación
};

// Reducer de autenticación
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // Acción que indica que se ha iniciado una solicitud de inicio de sesión
    case "LOGIN_REQUEST":
      return { ...state, loading: true }; // Establece loading en true

    // Acción que indica que el inicio de sesión fue exitoso
    case "LOGIN_SUCCESS":
      return { ...state, loading: false, user: action.payload }; // Establece loading en false y almacena los datos del usuario en user

    // Acción que indica que el inicio de sesión falló
    case "LOGIN_FAILURE":
      return { ...state, loading: false, error: action.payload }; // Establece loading en false y almacena el mensaje de error en error

    // Acción para establecer manualmente los datos del usuario
    case "SET_USER":
      return { ...state, user: action.payload }; // Almacena los datos del usuario en user

    // Acción por defecto: devuelve el estado actual sin cambios
    default:
      return state;
  }
};

export default authReducer;
