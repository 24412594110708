import { useWindowSize } from "../../components/hooks.js";
import React, { useState, useEffect, Children } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Dashboard.module.css";
import fondoImg from "../../assets/fondo-form.jpg";
import fondoMobile from "../../assets/fondoMobile.jpg";
import image1 from "../../assets/titulo_promo-form.png";
import image1Mobile from "../../assets/logo_promo.png";
import image2 from "../../assets/perrito_premios-form.png";
import image3 from "../../assets/logo_suave.png";
import { legal, terminos } from "../../components/datos";
import "remixicon/fonts/remixicon.css";
import Modal from "../../components/modals/ModalTerminos.jsx";
import image from "../../assets/codigo.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setPremioFisico } from "../../actions/premioFisicoActions.js";

const Dashboard = ({ ganadoresRegister = false, children }) => {
  const size = useWindowSize();
  const isMobile = size.width < 1023;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [code, setCode] = useState("");
  const [userData, setUserData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const handleVerifyCode = async () => {
    // Verificar si el código está en la base de datos simulada

    try {
      const resp = await axios.put(
        "https://guerreropromo-production.up.railway.app/codes/" + code,
        //"http://localhost:3000/codes/" + code,
        {
          ...state.user,
          id: code,
        }
      );

      //navigate("/no-premio");
      //return

      console.log("resp.data.data", {
        ...state.user,
        id: code,
      });

       localStorage.setItem("code", code);
      

      //console.log("locals", locals);
      //console.log("code", code);

      if(localStorage.getItem("fechaGanador")){
        //console.log("local---------------")
       // navigate("/premio-devolucion");
        navigate("/no-premio")
      }

      if (resp.data.data == "Premio Incorecto") {
        setErrorMessage("¡El código que ingresaste es inválido!");
       navigate("/no-premio");
       //navigate("/premio-devolucion");
      }

      if (resp.data.data == "Premio Devolucion") {
        let newDate = new Date()
        localStorage.setItem("fechaGanador", newDate);
        navigate("/premio-devolucion");
      }

      if (resp.data.data[1] == "Premio fisico") {

        let Traking = await axios.post(
          "https://guerrerodatosuser-production.up.railway.app/users" ,
          {
            nombre: state.user.name  ,
            DNI: state.user.document ,
            PREMIO: resp.data.data[2].UltimoPremio
          }
        );

        dispatch(setPremioFisico(resp.data.data[2]));
        let newDate = new Date()
        localStorage.setItem("fechaGanador", newDate);
        navigate("/premio-fisico");
      }

      if (resp.data.data == "Codigo inexistente") {
        setErrorMessage("Codigo inexistente");
        navigate("/no-premio");
      }
      //Premio Devolucion
    } catch (error) {
      console.log("error", error);
      navigate("/no-premio");
    }
  };

  //--------------------------------
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    image: "",
  });

  const openModal = () => {
    setShowModal(true);
  };

  const state = useSelector((state) => state.user);

  useEffect(() => {
    setUserData(state.user);

    console.log("state.user", state.user);
  }, []);

  function obtenerPrimeraPalabra(texto) {
    // Eliminar espacios en blanco al inicio y final del texto
    texto = texto?.trim();
    // Encontrar el primer espacio en blanco
    const primerEspacio = texto?.indexOf(" ");
    // Si no se encuentra ningún espacio, devolver el texto completo como la primera palabra
    if (primerEspacio === -1) {
      return texto;
    }
    // Devolver la primera palabra hasta el primer espacio encontrado
    return texto?.substring(0, primerEspacio);
  }

  const openModalWithContent = (type) => {
    const question = {
      title: "¿Dónde encuentro el código?",
      image,
    };
    const content =
      type === "terminos" ? terminos : type === "question" ? question : "";
    setModalContent({ content });
    setShowModal(true);
  };

  const replaceTerminosWithLink = (text) => {
    const parts = text.split("*Aplican términos y condiciones");
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && (
          <span
            className={styles.link}
            onClick={() => openModalWithContent("terminos")}
          >
            *Aplican términos y condiciones
          </span>
        )}
      </React.Fragment>
    ));
  };

  return (
    <section className={styles.container}>
      {isMobile ? (
        <img src={fondoMobile} alt="img" className={styles.fondoImgMobile} />
      ) : (
        <img src={fondoImg} alt="img" className={styles.fondoImg} />
      )}

      <div className={styles.content}>
        {isMobile && (
          <img src={image3} alt="img" className={styles.logoSuave2} />
        )}
        <div className={styles.imgContainer}>
          <Link to={"/"}>
            <img
              src={isMobile ? image1Mobile : image1}
              alt="img"
              className={styles.image1}
            />
          </Link>
          <img src={image2} alt="img" className={styles.image2} />
        </div>
        <div className={styles.boxContainer}>
          {!isMobile && (
            <img src={image3} alt="img" className={styles.logoSuave} />
          )}

          {!ganadoresRegister && (
            <div className={styles.infoContent}>
              <h2
                className={styles.title}
              >{`¡BIENVENID@ ${obtenerPrimeraPalabra(userData?.name)}!`}</h2>
              <p className={styles.text}>
                Ingresa el código que encontraste al interior del empaque de
                Suave y entérate si ganaste.
              </p>
              <div className={styles.inputContent}>
                <input
                  type="text"
                  name="codigo"
                  placeholder="Escríbe el código aquí"
                  value={code}
                  //onChange={(e) => setCode(e.target.value)}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\s/g, "");
                    if (newValue.length <= 6) {
                      setCode(newValue);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                />
                <i
                  className="ri-question-line"
                  onClick={() =>
                    openModalWithContent("question")
                  } /* onClick={openModal} */
                ></i>
                {errorMessage && (
                  <span
                    className={styles.errorMenssage}
                    style={{ color: "red" }}
                  >
                    {errorMessage}
                  </span>
                )}
              </div>
              <button onClick={handleVerifyCode} className={styles.button}>
                Validar
              </button>
            </div>
          )}

          {children}
        </div>
      </div>
      <span className={styles.textFooter}>
        {replaceTerminosWithLink(legal)}
      </span>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={modalContent.title}
        //content={modalContent}
        content={modalContent.content}
      />
    </section>
  );
};

export default Dashboard;
